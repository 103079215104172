

// Open and close mobile nav menu
function openNav() {
    // nav menu and mobileButton's 3 bars
    let navBar = document.getElementById('mobileMenu'),
        bar1 = document.getElementById('bar1'),
        bar2 = document.getElementById('bar2'),
        bar3 = document.getElementById('bar3');

    if (navBar.style.transform !== "translateX(0px)") {
        //opens mobile nav menu and adds X animation to mobileButton
        navBar.style.transform = "translateX(0px)";
        bar1.style.animation = "topX .5s forwards";
        bar2.style.animation = "midX .5s forwards";
        bar3.style.animation = "bottomX .5s forwards";
    } else {
        //closes mobile nav menu and adds hamburger animation to mobileButton
        navBar.style.transform = "translateX(-1000px)";
        bar1.style.animation = "topRX .5s both";
        bar2.style.animation = "midRX .5s both";
        bar3.style.animation = "bottomRX .5s both";
    }
}

function sizeSidebar() {
    if (!window.matchMedia('(max-width: 992px)').matches) {
        let sidebarContent = document.getElementById('sidebar');
        let mainContent = document.getElementById('mainContent');
        let fromTop = mainContent.offsetTop;
        let pageHeight = document.body.clientHeight - fromTop;
        let desktopTarget = document.getElementById('desktopSidebarTarget');
        sidebarContent.style.height = pageHeight + 'px';
        desktopTarget.appendChild(sidebarContent);
    }else{
        let sidebarContent = document.getElementById('sidebar');
        let mobileTarget = document.getElementById('mobileSidebarTarget');
            mobileTarget.appendChild(sidebarContent);
            sidebarContent.style.height = 'auto';
    }
}
window.onload = function(){
    sizeSidebar();
    setTimeout(sizeSidebar, 2000);
}

window.addEventListener('resize', sizeSidebar);
